import React from "react";
import Page from "../components/Page";
import { graphql } from "gatsby";
import BlogCard from "../components/Blog/BlogCard";


export default function Matteappar({ data }) {
  const { posts } = data.blog;

  return (
    <>
      <Page title={"Matteappar för skolan"}>
        <div className="">
          <div className="container px-5 py-6 mx-auto ">
            <br />
            <h1 className="font-bold text-4xl md:text-5xl max-w-xl text-gray-900 leading-tight mb-8">Matteappar för skolan</h1>
            <div className="blog-grid">
            <BlogCard key="hejalber" title={"Albert - Läroplattformen som lär unga matematik"}  description={"Om ditt barn har svårt för skolan kan vara bra att se över vilka skolhjälpmedel som ni kan använda för att hjälpa till. Ett sådant skolhjälpmedel är den digitala läroplattformen Albert."} link={"/recensioner/hejalbert/"} image={data.albert}/>

              <BlogCard key="jaramba" title={"Jaramba - En pedagogisk app för barn i förskoleåldern"}  description={"Jaramba är en pedagogisk app för barn i förskoleåldern med syftet att hjälpa barnet att bygga upp sitt ordförråd samtidigt som barnet också har roligt."} link={"/recensioner/jaramba/"} image={data.jaramba}/>
              <BlogCard key="zcooly" title={"Zcooly - Ett roligt mattespel för barn"}  description={"Zcooly är ett bra spel för att lära sig matematik! Det är ett mycket visuellt och interaktivt sätt att lära sig."} link={"/recensioner/zcooly/"} image={data.zcooly}/>

            {posts && posts.map((post) => {
              return <BlogCard key={post.id} title={post.frontmatter.title} description={post.excerpt} link={"/appar"+post.fields.slug} image={post.frontmatter?.coverImage} />
            })}
          </div>
          </div>
          <br />

        </div>
      </Page>
    </>
  );
}


export const pageQuery = graphql`
  query {
    blog: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC },
      filter: { fileAbsolutePath: { regex: "/(/apps/)/" },frontmatter: {category: { eq: "matte" } } }
    ) {
      posts: nodes {
        frontmatter {
          date(fromNow: true)
          title
          coverImage {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
        fields {
          slug
        }
        id
        excerpt(pruneLength: 120)
      }
    }
    jaramba: file(relativePath: { eq: "jaramba.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    zcooly: file(relativePath: { eq: "zcooly.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    albert: file(relativePath: { eq: "albert.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;

